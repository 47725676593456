import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth"
import { getFunctions } from "firebase/functions"

const firebaseConfig = {
    apiKey: "AIzaSyDTTfkZO09gTVFPdAWY4u-JHJVTtS7TPrQ",
    authDomain: "curso-de-framer.firebaseapp.com",
    projectId: "curso-de-framer",
    storageBucket: "curso-de-framer.appspot.com",
    messagingSenderId: "545941425847",
    appId: "G-JZP4RP4L6D",
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
const functions = getFunctions(app, "southamerica-east1")

await setPersistence(auth, browserLocalPersistence)

export { app, db, auth, functions }
